@tailwind base;
@tailwind components;
@tailwind utilities;

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #845ef7;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
